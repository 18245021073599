.searchBar {
  font-family: "Lexend Tera", sans-serif;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 40px;
}

.searchButton {
  margin: 20px;
  font-family: "Lexend Tera", sans-serif;
}

.breedLabel {
  font-family: "Lexend Tera", sans-serif;
  color: rgba(240, 238, 245, 0.719);
  margin: 20px;
}

.form-control {
  background-color: rgb(222, 224, 236);
  font-family: "Lexend Tera", sans-serif;
  width: auto;
  justify-content: center;
  text-align: center;
}

.search {
  margin: 20px;
  justify-content: center;
  text-align: center;
}

.breedImage {
  margin-bottom: 20px;
  width: 400px;
  height: 300px;
}

.catCard {
  width: 30rem;
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #254d67;
  color: rgba(235, 238, 243, 0.89);
  font-family: "Lexend Tera", sans-serif;
}

@media only screen and (max-width: 600px)  {
  .breedImage {
    width: 300px;
    height: 200px;
    margin-bottom: 20px;
  }

  .searchBar {
    font-family: "Lexend Tera", sans-serif;
    text-align: center;
    padding-left: unset;
    padding-right: unset;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-right: 10px;
    margin-left: 20px;
  }

  .search {
    margin: unset;
    justify-content: center;
    text-align: center;
  }

}